<template>
    <Header :isShow="isShow" />
    <div class="warper">
        <NewsTop :activeIndex="4" />
        <div class="content_box" ref="header">
            <div class="content max_small_box">
                <div class="first">
                    <div class="img">
                        <img src="@/assets/images/news.png" alt="">
                    </div>
                    <div class="text">
                        <p class="time">2021.4.21</p>
                        <p class="title">广东省东莞市质量监督检测中心  </p>
                        <p class="desc">东莞质检位于东莞市松山湖国家高新科技产业园，占地100亩。中心在建或已建成信息技术设备、纸制品、半导体光源产品、模具产品及智能加工装备等五个国家级质检中心，食品、珠宝首饰、光电产品、太阳能产品、家具、信息传输线缆产品、服装、毛织品、包装、塑料皮革、文教体育用品、生态家居产品、电子信息配件、智能制造装备等十四个省级检验站。</p>
                        <p class="more">查看更多<span class="iconfont">&#xe618;</span></p>
                    </div>
                </div>
                <div class="line"></div>
                <div class="second">
                    <div class="list">
                        <p class="time">2021.4.21</p>
                        <div class="text">
                            <p class="title">广东省东莞市质量监督检测中心  </p>
                            <p class="desc">东莞质检位于东莞市松山湖国家高新科技产业园，占地100亩。中心在建或已建成信息技术设备、纸制品、半导体光源产品、模具产品及智能加工装备等五个国家级质检中心，食品、珠宝首饰、光电产品、太阳能产品、家具、信息传输线缆产品、服装、毛织品、包装、塑料皮革、文教体育用品、生态家居产品、电子信息配件、智能制造装备等十四个省级检验站。</p>
                            <p class="more">查看更多<span class="iconfont">&#xe618;</span></p>
                        </div>
                    </div>
                    <div class="list">
                        <p class="time">2021.4.21</p>
                        <div class="text">
                            <p class="title">广东省东莞市质量监督检测中心  </p>
                            <p class="desc">东莞质检位于东莞市松山湖国家高新科技产业园，占地100亩。中心在建或已建成信息技术设备、纸制品、半导体光源产品、模具产品及智能加工装备等五个国家级质检中心，食品、珠宝首饰、光电产品、太阳能产品、家具、信息传输线缆产品、服装、毛织品、包装、塑料皮革、文教体育用品、生态家居产品、电子信息配件、智能制造装备等十四个省级检验站。</p>
                            <p class="more">查看更多<span class="iconfont">&#xe618;</span></p>
                        </div>
                    </div>
                    <div class="list">
                        <p class="time">2021.4.21</p>
                        <div class="text">
                            <p class="title">广东省东莞市质量监督检测中心  </p>
                            <p class="desc">东莞质检位于东莞市松山湖国家高新科技产业园，占地100亩。中心在建或已建成信息技术设备、纸制品、半导体光源产品、模具产品及智能加工装备等五个国家级质检中心，食品、珠宝首饰、光电产品、太阳能产品、家具、信息传输线缆产品、服装、毛织品、包装、塑料皮革、文教体育用品、生态家居产品、电子信息配件、智能制造装备等十四个省级检验站。</p>
                            <p class="more">查看更多<span class="iconfont">&#xe618;</span></p>
                        </div>
                    </div>
                    <div class="list">
                        <p class="time">2021.4.21</p>
                        <div class="text">
                            <p class="title">广东省东莞市质量监督检测中心  </p>
                            <p class="desc">东莞质检位于东莞市松山湖国家高新科技产业园，占地100亩。中心在建或已建成信息技术设备、纸制品、半导体光源产品、模具产品及智能加工装备等五个国家级质检中心，食品、珠宝首饰、光电产品、太阳能产品、家具、信息传输线缆产品、服装、毛织品、包装、塑料皮革、文教体育用品、生态家居产品、电子信息配件、智能制造装备等十四个省级检验站。</p>
                            <p class="more">查看更多<span class="iconfont">&#xe618;</span></p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="pagination max_small_box">
            <a-pagination :default-current="1" :total="100" />
        </div>
    </div>
    <div class="bottom_line"></div>
    <Footer />
    <FloatingFrame />
</template>

<script>
import { onBeforeMount, onMounted, onUnmounted, ref, reactive } from 'vue'
import Header from '../../components/header/Header';
import Footer from '../../components/footer/Footer';
import FloatingFrame from '../../components/floatingFrame/FloatingFrame';
import NewsTop from '../../components/news/NewsTop';

//页面滚动处理函数
const ScrollEffect = () => {

    const isShow = ref('')

    const handleScroll = (height) => {
        
        if(document.documentElement.scrollTop >= height){
            isShow.value = 1
        }else{
            isShow.value = 2
        }
    }

    return { handleScroll, isShow }
}


export default {
    name: 'NewsProclamation',
    components: { Header, Footer, FloatingFrame, NewsTop },
    setup() {

        //页面滚动处理函数
        const { 
            handleScroll, 
            isShow 
        } = ScrollEffect()

        //获取滑块到顶部的距离
        const header = ref(null)

        onMounted(() => {
            window.addEventListener('scroll', function () {
                handleScroll(header.value.offsetTop);
            })
        });

        return { isShow, header }
    }
}
</script>

<style lang="scss" scoped>
.content_box{
    margin-top: 40px;
}
.content{
    .first{
        display: flex;
        margin-bottom: 50px;
        @media screen and (max-width: 1200px){
            display: block;
            padding: 0 30px;
        }
        .img{
            width: 30%;
            @media screen and (max-width: 1200px){
                width: 100%;
            }
            img{
                width: 100%;
            }
        }
        .text{
            width: 63%;
            margin-left: 4%;
            display: flex;
            flex-direction: column;
            justify-content: center;
            @media screen and (max-width: 1200px){
                width: 100%;
                margin-top: 30px;
                margin-left: 0%;
            }
            .time{
                color: #A4A4A4;
                font-size: 20px;
                font-weight: 600;
            }
            .title{
                color: #101010;
                font-size: 20px;
                font-weight: 600;
                margin-top: 20px;
            }
            .desc{
                color: rgba(16, 16, 16, 100);
                font-size: 16px;
                margin-top: 20px;
                line-height: 20px;
            }
            .more{
                color: #101010;
                font-size: 16px;
                margin-top: 20px;
                width: max-content;
                border-radius: 10px;
                cursor: pointer;
                span{
                    margin-left: 15px;
                }
            }
        }
    }
    .line{
        width: 100%;
        height: 2px;
        background-color: #bbb;
        margin-top: 40px;
    }
    .second{
        margin-top: 40px;
        @media screen and (max-width: 1200px){
            padding: 0 30px;
        }
        .list{
            background-color: #F4F4F4;
            padding: 30px 30px;
            display: flex;
            align-items: center;
            margin-bottom: 30px;
            .time{
                color: rgba(164, 164, 164, 100);
                font-size: 20px;
                font-weight: 600;
                width: 12%;
                @media screen and (max-width: 900px){
                    display: none;
                }
            }
            .text{
                width: 85%;
                margin-left: 3%;
                @media screen and (max-width: 900px){
                    width: 100%;
                    margin-left: 0%;
                }
                .title{
                    color: rgba(16, 16, 16, 100);
                    font-size: 20px;
                    font-weight: 600;
                }
                .desc{
                    color: rgba(16, 16, 16, 100);
                    font-size: 16px;
                    margin-top: 30px;
                    line-height: 20px;
                }
                .more{
                    color: rgba(16, 16, 16, 100);
                    font-size: 16px;
                    margin-top: 30px;
                    text-align: right;
                    cursor: pointer;
                    span{
                        margin-left: 15px;
                    }
                }
            }
        }
    }
}
.pagination{
    text-align: center;
    padding-top: 80px;
}
.bottom_line{
    width: 100%;
    height: 2px;
    margin-top: 100px;
    background: #BBBBBB;
}
</style>